.login_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    gap: 2rem;

    &_selection {
        display: flex;
        gap: 20px;
        width: 100%;

        span {
            width: 50%;
            cursor: pointer;
            position: relative;
            text-align: center;

            &::after {
                content: '';
                width: 100%;
                height: 2px;
                background: transparent;
                position: absolute;
                bottom: -5px;
                right: 0;
            }

            &.active::after {
                background: #12c990;
            }
        }
    }

    .login {
        width: 100%;
    }

    .registration {
        display: flex;
        flex-direction: column;
        width: 100%;

        &_input {
            padding: 15px 10px;
            width: 100%;
            border: 1px solid #dedede;
            border-radius: 10px;

            &:focus-visible {
                outline: rgb(18, 201, 144) auto 1px;
            }

            &_errormsg {
                color: red;
                font-size: 12px;
            }
        }
    }
}