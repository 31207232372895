.radiobutton {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 16px;
    width: 50%;

    @media only screen and (max-width: 600px) {
        margin-bottom: 8px;
    }

    label {
        font-size: 16px;
        font-weight: 400;
        color: #042138;
        cursor: pointer;

        @media only screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    input {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        width: 1.5em;
        height: 1.5em;
        border: 1px solid #042138;
        border-radius: 50%;
        cursor: pointer;

        &::after {
           content: "";
           display: block;
           border-radius: 50%;
           width: 0.75em;
            height: 0.75em;
            margin: 4px;
        }

        &:checked {
            &::after {
               background-color: #042138;;
            }

            &:hover {
               background-color: transparent;
               border: 1px solid #042138;

                &::after {
                    background-color: #042138;
                }
            }
         }
    }
}