@import '../../assets/scss/mixins.scss';

.footer {
    position: relative;
    background-color: #042138;
    padding: 120px 0;

    @media screen and (max-width: 600px) {
        padding: 76px 0;
    }

    .container {
        @include wide-container;
    }

    img {
        width: 100px;
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        margin: 23px 0 25px;
    }

    .article {
        display: flex;
        align-items: center;

        a {
            text-decoration: none;
        }
        
        small {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #fff;
            margin: 0;
        }
        
        i {
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
        }
        
        &_location {
            background-image: url('../../assets/img/location.svg');
            width: 15px;
            height: 18px;
            margin-right: 20px;
        }
        
        &_email {
            background-image: url('../../assets/img/mail.svg');
            width: 19px;
            height: 17px;
            margin-right: 16px;
        }

    }

    ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style-type: none;
        margin: 0;
        padding: 29px 0 0;

        li {
            margin-right: 20px;

            a {
                cursor: pointer;
            }

            i {
                width: 20px;
                height: 20px;
                background-size: contain;
                display: block;
            }

            .facebook {
                background-image: url('../../assets/img/facebook.png');
            }
            
            .instagram {
                background-image: url('../../assets/img/instagram.svg');

            }
        }
    }

    &_background {
        position: absolute;
        width: 30vw !important;
        bottom: 0;
        right: 0;
        z-index: 0;

        @media screen and (max-width: 600px) {
            width: 45vw !important;
        }
    }
}