@import '../../assets/scss/mixins.scss'; 

.header {
    width: 100vw;
    // background-color: #042138;
    background-color: transparent;
    z-index: 10;
    position: fixed;

    .container {
        @include wide-container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
    
        .logo {
            width: 70px;

            @media only screen and (max-width: 600px) {
                width: 50px;
            }
        }
        
        nav {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                width: 200px;
                justify-content: space-around;
    
                a {
                    text-decoration: none;
                    color: white;
                    position: relative;

                    &.active:after {
                        content: '';
                        width: 65%;
                        height: 1px;
                        background: rgb(18, 201, 144);
                        position: absolute;
                        bottom: -5px;
                        margin: 0 auto;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
