.banner {
    display: flex;
    background-color: #042138;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    padding-left: 10rem;
    padding-right: 5rem;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        padding-top: 5rem;
        height: 100%;
        padding-left: 0;
        padding-right: 0;
        min-height: unset;
    }

    &_title {
        display: flex;
        flex-direction: column;
        max-width: 600px;
        text-transform: uppercase;
        color: white;
        font-style: normal;

        @media only screen and (max-width: 1200px) {
            max-width: 90%;
        }
        
        h1 {
            font-size: 48px;
            font-weight: 600;
            line-height: 56px;

            @media only screen and (max-width: 600px) {
                font-size: 40px;
                letter-spacing: 2px;
                text-align: center;
                margin: 1rem 0;
            }
        }

        p {
            font-size: 16px;
        }
    }

    img {
        width: 50%;
        height: auto;    
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 1200px) {
            width: 100%;
            height: auto;
        }
    }

    .counter {
        display: flex;
        align-items: center;

        &_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 100px;

            @media only screen and (max-width: 1200px) {
                min-width: 25%;
                width: 25%;
            }

            p, .number {
                margin: 0;
                font-size: 46px;
                font-weight: 400;
            }

            span {
                font-weight: 100;
                font-size: 14px;
            }
        }
    }

    .registerButton {
        align-self: flex-start;
        margin-top: 2rem;

        @media only screen and (max-width: 600px) {
            align-self: center;
        }
    }

    .logo {
        position: relative;
        height: 90%;

        &_move {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;
            animation: rotating 40s linear infinite;

            @media only screen and (max-width: 1200px) {
                margin: unset;
            }
        }
    }
}

@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}