@import '../../assets/scss/mixins.scss';

.about {
    background-color: #f7faff;

    .contaner {
        @include wide-container;
        padding: 5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media only screen and (max-width: 600px) {
            padding: 2rem 0;
        }

        h3 {
            font-size: 36px;
            line-height: 1;

            @media only screen and (max-width: 600px) {
                margin: 2rem 0;
            }
        }
    }
}