@import '../../assets/scss/mixins.scss'; 

.container {
    @include wide-container;
    display: flex;
    flex-direction: column;

    &_info {
        font-weight: 300;

        > div {
            display: flex;
            column-gap: 80px;
            flex-wrap: wrap;
        }

        &_title {
            font-size: 22px;
            font-style: italic;
            margin-bottom: 5px;
        }
    }

    > h3 {
        text-align: center;
    }
}

.apply {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f7faff;
    z-index: 1;
    padding: 5rem 0;

    @media only screen and (max-width: 600px) {
        padding: 2rem 0;
    }
    
    h3 {
        font-size: 36px;
        color: black;
    }

    &_wrapper {
        display: flex;
        border-radius: 50px;

        @media only screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
        }

         &_details {
            position: relative;
            padding: 2rem;
            width: 50%;

            @media only screen and (max-width: 1200px) {
                width: 100%;
            }

            @media only screen and (max-width: 600px) {
                padding: 0;
            }

            &_box {
                position: relative;
                background: rgba(255,255,255,0.5);
                backdrop-filter: blur(5px);
                padding: 2rem;
                border-radius: 25px;
                font-weight: 300;
                min-height: 590px;

                @media only screen and (max-width: 600px) {
                    min-height: unset;
                }

                hr {
                    margin: 2rem 0;
                    border-style: double;
                    width: 15%;
                    color: rgb(18, 201, 144);
                }

                p {
                    margin: 0;
                    padding-bottom: 5px;
                }

                &_info {
                    display: none;

                    > p {
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 15px;
                    }

                    hr {
                        width: 100%;
                        color: #12c990a1;
                        margin: 1rem 0;
                    }

                    ul {
                        margin: 0;
                    }
                }
            }

            &_title {
                padding: 10px;
                color: black;
                background: rgba(193,193,193,0.3);
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
                display: flex;
                flex-direction: column;
                width: 105px;
                line-height: 1;
                letter-spacing: 2px;
            }
            
            img {
                position: absolute;
                z-index: 0;
                top: 2rem;
                left: -9rem;
                width: 100%;
                transform: rotate(-90deg);
            }

        }

        &_info {
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: space-between;
            padding: 2rem;
            width: 50%;
            min-height: 542px;
            overflow: hidden;

            @media only screen and (max-width: 1200px) {
                width: 100%;
                min-height: 565px;
            }

            @media only screen and (max-width: 600px) {
                padding: 0;
                min-height: 477px;
            }

            &_next {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: flex-end;
                min-height: 56px;
                position: relative;

                &.active {
                    justify-content: flex-end;

                    div:first-child {
                        flex-direction: row;
                        margin-right: 12px;
                    }
                }

                &.active_payment {

                    .continue {
                        flex-direction: row;
                        margin-right: 12px;
                    }
                }
                
                div:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row-reverse;
                    cursor: pointer;         
                    margin-left: 12px;
                }
            }

            h3 {
                color: black;
                font-weight: 400;
                margin: 10px 0;
            }

            &_input {
                padding: 15px 10px;
                width: 100%;
                border: 1px solid #dedede;
                border-radius: 10px;

                &:focus-visible {
                    outline: rgb(18, 201, 144) auto 1px;
                }
            }

            .registerButton {
                display: none;
                position: absolute;
                right: 0;

                &.disabled {
                    pointer-events: none;
                    background: #12c990a1;
                }
            }
        }
    }
    
    &_workshops {
        justify-content: space-between;
        height: 370px;
        width: 100%;
        opacity: 0;
        display: none;

        @media only screen and (max-width: 600px) {
            height: auto;
        }

        > div {
            width: 50%;
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .radiobutton {
            width: 100%;
        }
    }

    &_payment {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        opacity: 0;
        display: none;

        .qrcodeButton {
            width: 100%;
            text-align: center;
            border: 1px solid #12c990;
            background: transparent;
            color: black;
            padding: 0.5rem;
            
            &:hover {
                background-color: #12c990;
                color: white;
            }
        }
    }

    &_thankYou {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
            width: 40px;
        }
    }

    &_registration {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.arrow {
    background: rgb(18, 201, 144);
    height: 2px;
    width: 20px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    margin: 0 0 0 10px;

    &.active {
        margin: 0 10px 0 0;

        &:before {
            right: 20px;
            bottom: -3px;
            transform: rotate(-135deg);
        }
    
        &:after {
            right: 20px;
            top: -3px;
            transform: rotate(135deg);
        }
    }

    &:before,
    &:after {
        content: "";
        background: rgb(18, 201, 144);
        position: absolute;
        height: 2px;
        width: 10px;
    }

    &:before {
        right: -10px;
        bottom: -3px;
        transform: rotate(-45deg);
    }

    &:after {
        right: -10px;
        top: -3px;
        transform: rotate(45deg);
    }
}

.price {
    display: flex;
    justify-content: space-between;

    &_total {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
    }
}

.continue {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;         
    margin-left: 12px;
    display: none;
}

.payment_checkbox {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;

    input[type="checkbox"] {
        min-width: 18px;
        height: 18px;
        appearance: none; /* Remove the default checkbox style */
        border: 2px solid #ccc; /* Add border */
        border-radius: 2px; /* Optional: make the checkbox rounded */
        position: relative;
        margin: 4px 8px 0 0;
        cursor: pointer;
    }
    
    /* When the checkbox is checked */
    input[type="checkbox"]:checked {
        background-color: #12c990; /* Change background color when checked */
        border-color: #12c990;
    }
    
    /* Add a checkmark when the checkbox is checked */
    input[type="checkbox"]:checked::before {
        content: '\2713'; /* Unicode for a checkmark */
        font-size: 16px;
        color: white;
        position: absolute;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}