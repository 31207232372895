@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none
}

input::selection {
  background-color: #12c990; /* Background color of selected text */
  color: white; /* Text color of selected text */
}