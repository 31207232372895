.description {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;

    &:not(:last-child) {
        margin-bottom: 84px;
    }

    &.right {
        flex-direction: row-reverse;
    }

    &_text {
        flex-basis: 50%;
        flex-grow: 0;
        max-width: 50%;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: .5px;
            text-transform: uppercase;
            color: #2c83e9;
        }
    
        h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 40px;
            color: #031737;
            padding: 8px 0 24px;
            margin: 0;
        }
    
        p {
            font-family: "Inter",sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #505f79;
            margin: 0;
            width: 90%;
        }
    }

    &_image {
        flex-basis: 50%;
        flex-grow: 0;
        max-width: 50%;
        display: flex;
        justify-content: center;

        img {
            max-width: 70%;
        }
    }
}