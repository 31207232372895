@import '../../assets/scss/mixins.scss';

.sponsors {
    @include wide-container;
    background-color: #fff;
    min-height: 155px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        min-height: auto;
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
    }

    img {
        max-width: 66px;
        height: 100%;

        @media screen and (max-width: 600px) {
            height: auto;
        }
    }

    &_list {
        display: flex;
        min-width: 60%;
        height: auto;
        justify-content: space-evenly;
        align-items: center;

        @media screen and (max-width: 600px) {
            margin: 0 auto;
            display: grid;
            grid-auto-flow: column;
            grid-gap: 1rem;
            gap: 1rem;
            height: auto;
            width: 100%;
            overflow-y: auto;
            overscroll-behavior-x: contain;
            -ms-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
            cursor: pointer;
        }
    }
}