.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:not(:last-child) {
        border-right: 1px solid #00000017;

        @media only screen and (max-width: 1200px) {
            border-right: none;
            border-bottom: 1px solid #00000017;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
        }
    }

    &_circle {
        background-color: #12c990;
        margin-right: 16px;
        border-radius: 15%;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        img {
            height: 50px;
        }
    }

    &_details {
        display: flex;
        color: #042138;

        span {
            font-size: 42px;
            font-weight: bold;
            margin-right: 10px;
        }
    
        p {
            font-size: 18px;
        }
    }

}