.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000004a;
    z-index: 10000;

    &_codes {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        max-width: 745px;
        padding: 3rem;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 5px;
        position: relative;

        > div {
            display: flex;
            justify-content: center;
        }

        b {
            font-size: 20px;
        }
    }

    &_close {
        position: absolute;
        top: 15px;
        right: 25px;
        cursor: pointer;
        font-weight: bold;
        background: #12c990;
        width: 30px;
        height: 30px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
    }
}
