.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000004a;
    z-index: 10000;

    &_codes {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        width: 90%;
        max-width: 745px;
        padding: 3rem;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 5px;
        position: relative;

        > div {
            display: flex;
            justify-content: center;
        }

        img {
            max-width: 310px;
            width: 90%;
        }

        &_iban {
            display: flex !important;
            flex-direction: column;
            width: 100%;

            hr {
                margin: 2rem 0;
                border-style: double;
                width: calc(54% - 105px);
                color: black;
            }
    
            .divider {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }
    
        }
    }

    &_close {
        position: absolute;
        top: 15px;
        right: 25px;
        cursor: pointer;
        font-weight: bold;
        background: #12c990;
        width: 30px;
        height: 30px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
    }

    &_ibanData {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 15px;
    }
}


.payment_iban {
    display: flex;
    border: 1px #00000057 solid;
    padding: 0.5rem;
    border-radius: 4px;
    width: 250px;

    input {
        border: none;
        background: transparent;
        outline: none;
        width: 90%;
    }

    .copy {
        width: 22px;
        height: 25px;
        background-size: contain;
        display: block;
        background-image: url('../../../assets/img/copy-regular.svg');
        cursor: pointer;
    }
}