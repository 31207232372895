@mixin wide-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    @media only screen and (max-width: 1200px) {
        width: 90%;
    }
}

@mixin container {
    padding: 0 5rem;
}