@import '../../assets/scss/mixins.scss'; 

.homepage {

    .info {
        background-image: url('../../assets/img/galebove-night-color.png');
        background-size: cover;
        background-position: center;
        display: flex;
        padding: 3rem 0;
        
        .container {
            @include wide-container;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 1200px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    
        &_box {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                @media only screen and (max-width: 1200px) {
                    margin-bottom: 25px;
                }
            }
    
            &_icon {
                background-color: #12c990;
                margin-right: 16px;
                border-radius: 50%;
                min-width: 70px;
                height: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                img {
                    height: 50px;
                }
    
            }
    
            &_details {
                color: #fff;
                max-width: 255px;

                @media only screen and (max-width: 1200px) {
                    max-width: 100%;
                }
    
                h4 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                }
    
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    margin: 0;
                }
            }
    
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 85px 0 0;
        background: #f7faff;
    }
}



