.button {
    cursor: pointer;
    border-radius: 8px;
    color: white;
    padding: 1rem 2rem;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #12c990;
    align-self: flex-end;
    cursor: pointer;
}

.button_loading .button__text {
    visibility: hidden;
    opacity: 0;
}
  
.button_loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }
  
    to {
        transform: rotate(1turn);
    }
}